<template>
	<div>

    
    <van-field
      v-model="phone"
      type="number"
      label="原手机号"
      :label-width="100"
      disabled
    />
    <van-field
    style="display: flex;justify-content: space-between;align-items: center;"
      center
      clearable
      v-model="code"
      label="验证码"
      placeholder="请输入验证码"
    >
      <van-button class="mybtn" @click="getCode" slot="button" type="primary" size="middle" plain>
        {{sendBtnText }}
      </van-button>
    </van-field>
    <div class="common_block">
      <van-button type="warning" block @click="postPhone">{{translate('submit_amount_options')}}</van-button>
    </div>
	</div>
</template>

<script>
	export default {
		name: 'personal_phone',
		data() {
			return {
				phone:this.$route.query.phone,
        code:'',
        sendBtnText:'获取验证码',
        // 计时器对象
        timer: null,
        // 倒数60秒
        counter: 60,
        iscou:true
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
		},
		methods: {
      getCode(){
        if(!this.iscou){
          return false;
        }
        this.iscou=false;
        this.countDown();
        this.$axios({
					method: 'post',
					url: 'account_account/changeMobileSmsCode', 
					data: {
            token: this.$route.query.token,
            country_code:'086',
            telephone:this.phone
          }
				}).then ((res) => {
					if (res.success) {
            // this.$dialog.alert({
						// 	message: "验证码获取成功"
						// })
						console.log(1)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      postPhone(){
        if(this.code==""){
          this.$dialog.alert({
						message: '请输入验证码'
					})
          return;
        }
        this.$axios({
					method: 'post',
					url: 'account_account/checkMobile', 
					data: {
            customer_id:this.$route.query.customer_id,
            token: this.$route.query.token,
            country_code:'086',
            telephone:this.phone,
            sms_code:this.code,
          }
				}).then ((res) => {
					if (res.success) {
            this.$router.replace('/personal/phone2?token='+this.$route.query.token+'&phone='+this.phone+'&customer_id='+this.customer_id)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
			/**
       * 倒计时
       */
       countDown () {
        // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
        this.timer = setInterval(() => {
          // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
          this.sendBtnText = `(${this.counter}秒)后重新发送`
          this.counter--
          if (this.counter < 0) {
            // 当计时小于零时，取消该计时器
            clearInterval(this.timer)
            this.counter=60;
            this.sendBtnText='获取验证码';
            this.iscou=true;
          }
        }, 1000)
      },
      isValidPhoneNumber(phoneNumber) {
        return /^1\d{10}$/.test(phoneNumber);
      }
		},
	}
</script>
<style scoped>
  .mybtn{
    border:none;
    color: rgb(255,140,0);
  }
</style>
